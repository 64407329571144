import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import { BuyerOffer } from '../types';
import { useAppDispatch } from '@/src/store';
import { GlobalModalTypes, openModal } from '@/src/state/globalModal/slice';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { OfferCTATypes, typedOffersEvent } from '@/src/state/tracking/fullstory/customEvents/offers';

export const PendingSellerInitiatedOfferCTAs: FC<{ offer: BuyerOffer }> = ({ offer }) => {
  const dispatch = useAppDispatch();
  const { fullStoryAndGTMEvent } = useFullStory();

  return (
    <>
      {/* TODO: translations */}
      <Button
        onClick={() => {
          fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
            type: OfferCTATypes.OpenAcceptOffer,
            offerKey: offer.key,
            listingKey: offer.listing.key
          });
          dispatch(
            openModal({
              modalType: GlobalModalTypes.BUYER_ACCEPT_OFFER,
              modalProps: {
                buyerAcceptOffer: { offer: offer.toSerializable() }
              }
            })
          );
        }}
        variant="contained"
        color="primary"
        id="offer-cta-accept-offer"
      >
        ACCEPT OFFER
      </Button>
      <Button
        onClick={() => {
          fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
            type: OfferCTATypes.OpenDeclineOffer,
            offerKey: offer.key,
            listingKey: offer.listing.key
          });
          dispatch(
            openModal({
              modalType: GlobalModalTypes.DECLINE_OFFER,
              modalProps: { declineOffer: { offerKey: offer.key, listingKey: offer.listing.key, isBuyer: true } }
            })
          );
        }}
        variant="contained"
        color="secondary"
        id="offer-cta-decline-offer"
      >
        DECLINE OFFER
      </Button>
      <Button
        onClick={() => {
          fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
            type: OfferCTATypes.CounterOffer,
            listingKey: offer.listing.key,
            offerKey: offer.key
          });

          dispatch(
            openModal({
              modalType: GlobalModalTypes.MAKE_OFFER,
              // TODO ENG-2363 new flow doesn't actually use id so we don't need it in the props after cleanup
              modalProps: {
                makeOffer: { listing: { ...offer.listing.toSerializable(), id: 0 }, offer: offer.toSerializable(), isCounter: true }
              }
            })
          );
        }}
        id="offer-cta-counter-offer"
        variant="outlined"
        color="info"
        size="large"
      >
        <Typography variant="button14AllCaps">COUNTER OFFER</Typography>
      </Button>
      <Button
        onClick={() => {
          fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
            type: OfferCTATypes.MessageSeller,
            listingKey: offer.listing.key,
            offerKey: offer.key
          });
          dispatch(
            openModal({
              modalType: GlobalModalTypes.MESSAGE_SELLER,
              modalProps: { messageSeller: { listingKey: offer.listing.key } }
            })
          );
        }}
        id="offer-cta-message-seller"
        fullWidth
        size="large"
        variant="outlined"
        color="info"
      >
        <Typography variant="button14AllCaps">MESSAGE SELLER</Typography>
      </Button>
    </>
  );
};
