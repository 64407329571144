import { BooleanValue, Route, SimpleDateFormat, Url } from '../common';
import { FeatureSettings } from '@/src/profile/types';

// -- #results
// -----------------------------------------------

export class UserFeatureSettings {
  ems: FeatureSettings = new FeatureSettings({});
  constructor(arg: Partial<UserFeatureSettings>) {
    Object.assign(this, arg);
  }
}
export interface RepresentativeLite {
  readonly first_name: string;
  readonly image_url?: Url;
}
export interface Representative extends RepresentativeLite {
  readonly last_name: string;
  readonly phone?: string;
  readonly email?: string;
  readonly image_url: Url;
}

export type UserRoleValue = number; // -- TODO type

export interface UserLite {
  readonly first_name: string;
  readonly last_name: string;
  readonly hash_key: string;
  readonly representative?: RepresentativeLite;
}

export interface User {
  readonly id: number;
  readonly role: UserRoleValue;
  readonly first_name: string;
  readonly last_name: string;
  readonly phone: string;
  readonly email: string;
  readonly email_verified: boolean;
  feature_settings: UserFeatureSettings;
  readonly contact_preference: number;
  readonly contact_handle: string;
  readonly language: string;
  readonly title: string;

  readonly user_company: {
    readonly name?: string;
    readonly info?: string;
    readonly country?: string;
    readonly industry_type?: number;
    readonly type?: number;
    readonly is_ems: boolean;
    readonly id: number;
    readonly self_serve_rate_percentage?: number;
  };
}

export interface GenerateRepresentativeProps {
  readonly first_name?: string;
  readonly last_name?: string;
  readonly phone?: string;
  readonly email?: string;
  readonly image_url?: Url;
}

// -- todo #182884799 is this still relevant? compare to User.user_company
export interface UserCompany {
  readonly id: number;
  readonly name: string;
  readonly type: number;
  readonly industry_type: number;
  readonly country: string;
  readonly title: string;
}

// -- #payloads
// -----------------------------------------------

export interface CreateUserSession {
  readonly email: string;
  readonly password: string;
}

export interface CreateUserContact {
  readonly is_offer: BooleanValue;
  readonly offer_amount: number;
  readonly notes: string;
  readonly listing_id?: number;
  readonly model_id?: number;
  readonly from_url: string;
}

export interface CreateAnonContact extends CreateUserContact {
  readonly email: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly phone: string;
  readonly contact_preference: number;
  readonly contact_handle: string;
  readonly company: string;
}

export interface CreateEnterpriseContact extends CreateAnonContact {
  readonly is_enterprise_customer: BooleanValue;
}

export interface CreateUser {
  readonly first_name?: string;
  readonly last_name?: string;
  readonly email: string;
  readonly password: string;
  readonly phone?: string;
  readonly language?: string;
  readonly is_buyer?: BooleanValue;
  readonly is_seller?: BooleanValue;
  readonly is_opt_in?: BooleanValue;
  readonly tos?: BooleanValue;
  readonly title?: string;
  readonly registration_params?: {
    readonly [key: string]: string;
  };

  readonly user_company?: {
    readonly name?: string;
    readonly info?: string;
    readonly country?: string;
    readonly industry_type?: number;
    readonly type?: number;
  };
  readonly turnstile_data: string;
}

export interface UpdateUser {
  readonly password?: string;
  readonly old_password?: string;
  readonly first_name?: string;
  readonly last_name?: string;
  readonly email?: string;
  readonly phone?: string;
  readonly language?: string;
  readonly is_buyer?: boolean;
  readonly is_seller?: boolean;
  readonly contact_preference?: number;
  readonly contact_handle?: string;
  readonly title?: string;

  readonly user_company?: {
    readonly name?: string;
    readonly info?: string;
    readonly country?: string;
    readonly industry_type?: number;
    readonly type?: number;
  };
}

export interface UpdateUserWithPassword extends UpdateUser {
  readonly password?: string;
  readonly old_password?: string;
}

export interface CreateUserSession {
  readonly email: string;
  readonly password: string;
}

export interface RequestUserResetToken {
  readonly email: string;
}

export interface VerifyEmailToken {
  readonly verifyEmailToken: string;
}

export interface ResetUserPasswordPayload {
  readonly email: string;
}

export interface UpdateUserPasswordPayload {
  readonly email: string;
  readonly reset_token: string;
  readonly new_password: string;
}

export type UserActivityType = 'LISTING_CREATED' | 'UNCONFIRMED_LISTING_CREATED' | 'SETTLEMENT_COMMENT' | 'SETTLEMENT_STEP';
export interface UserActivity {
  readonly type: UserActivityType;
  readonly date: SimpleDateFormat;
  readonly message: string;
  readonly route?: Route;
}
