import { stringFromKey, boolFromKey, genericObjectFromKey } from '../common/lib';
import { ListingPhoto } from '../entities/listingPhoto';

interface ListingInfo {
  make_model: string;
  key: string;
  photo?: ListingPhoto | null;
}

export class MessageThread {
  key = '';
  listing_info: ListingInfo = { make_model: '', photo: null, key: '' };
  messages: Message[] = [];
  receiver_alias = '';

  constructor(args: Partial<MessageThread> = {}) {
    Object.assign(this, args);
  }

  toSerializable(): MessageThreadSerializable {
    return {
      key: this.key,
      listing_info: this.listing_info,
      messages: this.messages,
      receiver_alias: this.receiver_alias
    };
  }

  static anyToDto(a: any): MessageThread {
    const s = MessageThread.anyToSerializable(a);
    const messages = (a.messages ?? [])?.map((m: any) => Message.anyToDto(m));
    return new MessageThread({ ...s, messages });
  }

  static anyToSerializable(a: any): MessageThreadSerializable {
    const listingInfo = genericObjectFromKey<ListingInfo>('listing_info', a);
    const make_model = stringFromKey('make_model', listingInfo);
    const listingKey = stringFromKey('key', listingInfo);
    return {
      key: stringFromKey('key', a),
      listing_info: { make_model, photo: listingInfo.photo ?? null, key: listingKey },
      messages: (a.messages ?? []).map((m: any) => Message.anyToSerializable(m)),
      receiver_alias: stringFromKey('receiver_alias', a)
    };
  }
}

const emptyMessageThread = { ...new MessageThread() };
export type MessageThreadSerializable = typeof emptyMessageThread;

export class Message {
  key = '';
  body = '';
  created_at = '';
  current_user_or_user_company_is_sender = false;
  sender_alias = '';
  seen = false;

  constructor(args: Partial<Message> = {}) {
    Object.assign(this, args);
  }

  toSerializable(): MessageSerializable {
    return {
      key: this.key,
      body: this.body,
      created_at: this.created_at,
      current_user_or_user_company_is_sender: this.current_user_or_user_company_is_sender,
      sender_alias: this.sender_alias,
      seen: this.seen
    };
  }

  static anyToDto(a: any): Message {
    const s = Message.anyToSerializable(a);
    return new Message(s);
  }

  static anyToSerializable(a: any): MessageSerializable {
    return {
      key: stringFromKey('key', a),
      body: stringFromKey('body', a),
      created_at: stringFromKey('created_at', a),
      current_user_or_user_company_is_sender: boolFromKey('current_user_or_user_company_is_sender', a),
      sender_alias: stringFromKey('sender_alias', a),
      seen: boolFromKey('seen', a)
    };
  }
}

const emptyMessage = { ...new Message() };
export type MessageSerializable = typeof emptyMessage;
