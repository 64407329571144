import axios from 'axios';
import { AppDispatch, AppState } from '../store';
import { ERROR_MESSAGES, MOOV_API_URL } from '../constants';
import { AddressSerializable, valueFromKey } from '../common/lib';
import { captureException } from '@sentry/nextjs';
import {
  BuyerOffer,
  BuyerOfferSerializable,
  ReadableOfferStatus,
  SellerOffer,
  SellerOfferGroup,
  SellerOfferGroupSerializable
} from './types';
import {
  loadBuyerOffers,
  loadSingleBuyerOffer,
  loadSellerOffers,
  setError,
  setLoading,
  setStatusFilter,
  replaceBuyerOffer,
  updateSellerOfferGroup,
  updateBuyerOffer
} from './slice';
import { openError } from '../state/banner/slice';

export interface MakeOfferPayload {
  offer_amount: number;
  destination_address: AddressSerializable;
  offer_note: string;
  offer_subject_to: boolean;
  offer_subject_to_note: string;
  required_by: string;
  terms_and_conditions: boolean;
}

const _createOffer = async (dispatch: AppDispatch, { body, listingKey }: { body: MakeOfferPayload; listingKey: string }) => {
  return axios
    .post<void>(
      `${MOOV_API_URL}/auth/offers/listing/${listingKey}`,
      { ...body },
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      }
    )
    .then((resp) => {
      const offer = BuyerOffer.anyToSerializable(valueFromKey('data', resp.data));
      dispatch(loadSingleBuyerOffer(offer));
    });
};

const _updateOffer = async (dispatch: AppDispatch, { body, offerKey }: { body: MakeOfferPayload; offerKey: string }) => {
  return axios
    .put<void>(
      `${MOOV_API_URL}/auth/offers/buyer/${offerKey}`,
      { ...body },
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      }
    )
    .then((resp) => {
      const offer = BuyerOffer.anyToSerializable(valueFromKey('data', resp.data));
      dispatch(replaceBuyerOffer({ offer, oldOfferKey: offerKey }));
    });
};

const _buyerCounterOffer = async (dispatch: AppDispatch, { body, offerKey }: { body: MakeOfferPayload; offerKey: string }) => {
  return axios
    .post<void>(
      `${MOOV_API_URL}/auth/offers/buyer/${offerKey}/counter`,
      { ...body },
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      }
    )
    .then((resp) => {
      const offer = BuyerOffer.anyToSerializable(valueFromKey('data', resp.data));

      dispatch(replaceBuyerOffer({ offer, oldOfferKey: offerKey }));
    });
};

export function createOrUpdateOffer({
  body,
  listingKey,
  offerKey,
  isCounter
}: {
  body: MakeOfferPayload;
  listingKey: string;
  offerKey?: string;
  isCounter?: boolean;
}) {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    if (typeof offerKey === 'string') {
      return isCounter ? _buyerCounterOffer(dispatch, { body, offerKey }) : _updateOffer(dispatch, { body, offerKey });
    }

    return _createOffer(dispatch, { body, listingKey });
  };
}

export function fetchBuyerOffers() {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    dispatch(setLoading());
    return axios
      .get<void>(`${MOOV_API_URL}/auth/offers/buyer`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((resp) => {
        const body = valueFromKey<Array<any>>('data', resp.data);

        dispatch(loadBuyerOffers(body.map((of) => BuyerOffer.anyToSerializable(of))));
      })
      .catch((e) => {
        dispatch(setError());
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
}

export function fetchSellerOffers() {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    dispatch(setLoading());

    return axios
      .get<void>(`${MOOV_API_URL}/auth/offers/seller`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((resp) => {
        const body = valueFromKey<Array<any>>('data', resp.data);
        dispatch(loadSellerOffers(body.map((of) => SellerOfferGroup.anyToSerializable(of))));
      })
      .catch((e) => {
        dispatch(setError());
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
}

export function sellerAcceptOffer(offer: SellerOffer) {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .put<void>(`${MOOV_API_URL}/auth/offers/seller/${offer.key}/accept`, undefined, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((resp) => {
        const body = valueFromKey<SellerOfferGroupSerializable>('data', resp.data);
        dispatch(updateSellerOfferGroup(SellerOfferGroup.anyToSerializable(body)));
        //Set the filter to accepted so the offer is still on the page
        dispatch(setStatusFilter(ReadableOfferStatus.ACCEPTED));
      });
  };
}

export const sellerCounterOffer = ({ offerKey, body }: { body: { offer_amount: number; offer_note: string }; offerKey: string }) => {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .post<void>(`${MOOV_API_URL}/auth/offers/seller/${offerKey}/counter`, body, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((resp) => {
        const offer = SellerOfferGroup.anyToSerializable(valueFromKey('data', resp.data));

        dispatch(updateSellerOfferGroup(offer));
      });
  };
};

export function buyerAcceptOffer(offerKey: string, body?: { destination_address: AddressSerializable }) {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .put<void>(`${MOOV_API_URL}/auth/offers/buyer/${offerKey}/accept`, body, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((resp) => {
        const body = valueFromKey<BuyerOfferSerializable>('data', resp.data);
        dispatch(updateBuyerOffer(BuyerOffer.anyToSerializable(body)));
        //Set the filter to accepted so the offer is still on the page
        dispatch(setStatusFilter(ReadableOfferStatus.ACCEPTED));
      });
  };
}

export function declineOffer(offerKey: string, reason: string, isBuyer?: boolean) {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return isBuyer ? _buyerDeclineOffer(offerKey, reason)(dispatch, _getState) : _sellerDeclineOffer(offerKey, reason)(dispatch, _getState);
  };
}

export function _sellerDeclineOffer(offerKey: string, reason: string) {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .put<void>(
        `${MOOV_API_URL}/auth/offers/seller/${offerKey}/decline`,
        { reason },
        {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          withCredentials: true
        }
      )
      .then((resp) => {
        const body = valueFromKey<SellerOfferGroupSerializable>('data', resp.data);
        dispatch(updateSellerOfferGroup(SellerOfferGroup.anyToSerializable(body)));
      });
  };
}

export function _buyerDeclineOffer(offerKey: string, reason: string) {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .put<void>(
        `${MOOV_API_URL}/auth/offers/buyer/${offerKey}/decline`,
        { reason },
        {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          withCredentials: true
        }
      )
      .then((resp) => {
        const body = valueFromKey<BuyerOfferSerializable>('data', resp.data);

        dispatch(updateBuyerOffer(BuyerOffer.anyToSerializable(body)));
      });
  };
}

export function validateCanMakeOfferOnListing(listingKey: string) {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    dispatch(setLoading());
    return axios
      .get(`${MOOV_API_URL}/auth/offers/validate/makeoffer/listing/${listingKey}`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((resp) => {
        const canMakeOffer = valueFromKey<boolean>('data', resp.data);
        return canMakeOffer;
      });
  };
}
