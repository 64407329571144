import { FC, useState } from 'react';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { FormControl, FormLabel, Stack, Typography } from '@mui/material';
import { ERROR_MESSAGES } from '@/src/constants';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { closeModal } from '@/src/state/globalModal/slice';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { getModalState } from '@/src/state/globalModal/selectors';
import { colors, spacing } from '@/src/design/designConstants';
import { captureException } from '@sentry/nextjs';
import { Controller, useForm } from 'react-hook-form';
import { buyerAcceptOffer } from '@/src/offers/thunks';
import { OfferCTATypes, typedOffersEvent } from '@/src/state/tracking/fullstory/customEvents/offers';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { parseUSDCurrencyStr } from '@/src/utils';
import { RequiredLabel } from '@/src/design/bits/requiredLabel';
import { useLocalizer } from '@/src/localization';
import { getLogisticCountries } from '@/src/state/logistics/selectors';
import { CountryAutocomplete } from '@/src/design/kilos/countrySelect';
import joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

const joiSchema = joi.object({
  destination_address: joi.object({
    country: joi.string().min(1).required()
  })
});

export const BuyerAcceptOfferModal: FC = () => {
  const dispatch = useAppDispatch();
  const { modalProps } = useAppSelector(getModalState);
  const { fullStoryAndGTMEvent } = useFullStory();
  const { openErrorAlert } = useGlobalAlertBanner();
  const [currentStep, setCurrentStep] = useState(1);

  const { offer } = modalProps.buyerAcceptOffer ?? {};
  const defaultValues = {
    destination_address: { country: '' },
    ...(offer && { destination_address: { country: offer.offer.destination_address.country } })
  };

  const hookForm = useForm<{ destination_address: { country: string } }>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(joiSchema)
  });
  hookForm.watch('destination_address');

  const countryOptions = useAppSelector(getLogisticCountries);
  const loc = useLocalizer();
  if (!offer) {
    return null;
  }

  return (
    <WorkflowModal
      title=""
      modalOpen
      closeModal={() => dispatch(closeModal())}
      totalSteps={1}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      // TODO: Translations - No
      dismissButtonContent={'No'}
      dismissButtonSubmit={async () => {
        dispatch(closeModal());
      }}
      // TODO: Translations - Yes
      actionButtonContent={'Yes'}
      actionButtonSubmit={async () => {
        fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
          type: OfferCTATypes.ConfirmAcceptOffer,
          offerKey: offer.key,
          listingKey: offer.listing.key
        });
        //If there is already a country on the offer, don't send it to the API
        const body = Boolean(offer.offer.destination_address.country)
          ? undefined
          : { destination_address: hookForm.getValues('destination_address') };
        return dispatch(buyerAcceptOffer(offer.key, body))
          .then(() => {
            dispatch(closeModal());
          })
          .catch((e) => {
            captureException(e);
            openErrorAlert(ERROR_MESSAGES.REFRESH);
          });
      }}
      actionButtonDisabled={!hookForm.formState.isValid}
      actionButtonId="buyer-accept-offer-modal-submit"
      dismissButtonId="buyer-accept-offer-modal-skip"
    >
      <Stack sx={{ width: { d: '600x' } }} spacing={spacing['3xl']}>
        {/* TODO: translations */}
        <Stack spacing={spacing.lg} variant="center">
          <Typography variant="h5" textAlign={'center'}>
            Are you sure you want to accept this offer?
          </Typography>
          <Typography variant="body1" color={colors.smokeyGray['500']} textAlign={'center'}>
            {`You will be accepting an offer of ${parseUSDCurrencyStr(offer.offer.offer_amount)} USD for ${offer.listing.make_model}.`}
          </Typography>
        </Stack>

        {!offer.offer.destination_address.country && (
          <Controller
            name="destination_address.country"
            control={hookForm.control}
            render={({ field }) => (
              <FormControl error={Boolean(hookForm.formState.errors['destination_address'])} fullWidth>
                <FormLabel error={Boolean(hookForm.formState.errors['destination_address'])}>
                  {loc.Listing.Location}
                  <RequiredLabel error={Boolean(hookForm.formState.errors['destination_address'])} />
                </FormLabel>
                <CountryAutocomplete
                  // Ref undefined unsets the ref on field, we get a lot of console errors
                  // w/o this and it doesn't break functionality
                  {...{ ...field, ref: undefined }}
                  // TODO: translations
                  placeholder="Choose Destination Country"
                  error={Boolean(hookForm.formState.errors['destination_address'])}
                  data-testid="buyer-accept-offer-country-select"
                  options={countryOptions}
                  value={countryOptions.find((option) => option.id === hookForm.getValues('destination_address').country) || null}
                  handleChange={(country) => {
                    field.onChange(country?.id || '');
                    hookForm.trigger('destination_address');
                  }}
                  size="medium"
                />
              </FormControl>
            )}
          />
        )}
      </Stack>
    </WorkflowModal>
  );
};
