import { Chip } from '@mui/material';

import { colors } from '../design/designConstants';
import { Message } from './types';

export const SenderChip: React.FC<{ message: Message }> = ({ message }) => {
  return (
    <Chip
      sx={{
        width: 'fit-content',
        ...(message.current_user_or_user_company_is_sender
          ? {
              border: `1px solid ${colors.moovBlue[200]}`,
              backgroundColor: colors.moovBlue[50],
              color: colors.moovBlue[500]
            }
          : {
              border: `1px solid ${colors.magenta[200]}`,
              backgroundColor: '#FFFAFD',
              color: colors.magenta[500]
            })
      }}
      label={message.sender_alias}
      size="medium"
    />
  );
};
